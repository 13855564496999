.background {
    background-color: #e9ecef;
    }
.topDiv {
        cursor: pointer;
        background-color: #ffffff;
        margin: 10px;
        font-size: 10px;
        min-width: 332px;
        border-bottom-style: solid;
        border-bottom-width: 0.666667px;
        border-top-style: solid;
        border-top-width: 0.666667px;
        border-left-style: solid;
        border-left-width: 0.666667px;
        border-right-style: solid;
        border-right-width: 0.666667px;
        border-bottom-color: rgba(0, 0, 0, 0.125);
        border-top-color: rgba(0, 0, 0, 0.125);
        border-left-color: rgba(0, 0, 0, 0.125);
        border-right-color: rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
        border-top: 3px solid green;
        box-shadow: rgba(229, 234, 250, 0.75) 0px 0px 10px 0px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        width: 332px;
        box-sizing: border-box;
        border-image-outset: 0;
        border-image-repeat: stretch;
        border-image-slice: 100%;
        border-image-source: none;
        border-image-width: 1;
        background-clip: border-box;
}
.avatar{
        text-align: left;
        align-items: center;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        background-color: #212121;
        color: #ffffff;
        border-radius: 30px;
        height: 32px;
        width: 32px;
        font-family: HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 13;
}
      
.cardTitle {
        font-size: 1.3rem;
        color: green;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        word-wrap: break-word;
        vertical-align: middle;
        width: 250px;
        text-align: center;
       
}
      
.cardHead {
        display: flex;
        cursor: pointer;
        padding-top: 10px;
        padding-bottom: 8px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: rgba(0,0,0,.03);
        border-bottom: 1px solid rgba(0,0,0,.125);
}
      
.cardDetails {
        align-items: flex-start;
        margin: 0;
        padding: 0;
        width: 100%;
}
      
.divider {
        width: 100%;
        margin-bottom: 0.6rem;
        border: 0.6;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
}
      
.blocks {
        padding-left: 20px;
        margin-bottom: 0.8rem;
        width: 100%;
        font-family: HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
        list-style-type: none;
        line-height: 20px;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        margin-top: 5px;
}
.textFiled{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px;
    max-width:272px ;
    width: 272px;
}

.textFiled label{
    position:absolute;
    top: 0;
    left: 0;
    color: #212121;
    font-size: 14px;
    pointer-events: none;
    transform-origin: top left;
    transition: transform 0.2s ease-out, font-size 0.2s ease-out , color 0.2s ease-out;
    transform: translateY(15px) scale(1);
}

.textFiled.focused label{
    transform: translateY(0) scale(0.8);
    color:black;
}

.textFiled .form-control{
    border: 1px solid black;
    border-radius: 4px;
    width: 100%;
    transition: border-color 0.15s ease-in-out , box-shadow 0.15s ease-in-out;

}
.textFiled .form-control:focus{
    border-color: black;
    border: 2px solid ;
    box-shadow: none ;
}
.nodata{
    margin-top: 20px;
}

.cardsGap{
    display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.placeHolder{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.center-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh !important;
}
.custom-spinner {
    width: 80px !important;
    height: 80px !important;
    border-width: 10px !important;
    color: green;
}

.noDataParent{
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.nodataChild{
    background-color: #e9ecef;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 15px;
    color: black;
}
  