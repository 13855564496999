.light-green-color {
    color:#3d4c0a;
}
.dark-green-color{
    color:#293306;
}
.charcoal-color{
    color:#474747;
}
.light-silver-color{
    color:#d9d9d9;
}
.white-color{
    color:#ffffff;
}
.pink-color{
    color:#e74b7a;
}
.orange-color{
    color:#fb6107;
}
.silver-color{
    color:#979797;
}
.light-blue-black-color{
    color:#303135;
}
.blue-marguerite-color{
    color:#6269cd ;
}
.deep-sea-green-color{
    color:#124e5c;
}
.dark-jungle-green{
    color:#212529;
}
.iridium-color{
    color:#3e3e3e;
}
.green-color{
    color:green;
}
.rangoon-green-color{
    color:#212121;
}
.black-color{
    color:black;
}
.medium-grey-color{
    color:#808080;
}
.racing-green-color{
    color:#16181b;
}
.camo-color{
    color:#859940;
}
.windows-blue-color{
    color:#337ab7;
}
.satin-linen-color{
    color:#E0E5CF;
}
.deep-cove-color{
    color:#0D1A40;
}
.dark-aquamarine-color{
    color:#006D82;
}
.guardsman-red-color{
    color:#C20000;
}
.red-color{
    color:red;
}
.green-white-color{
    color:#E8E8E8;
}
.background-deep-cove{
    background-color: #0D1A40;
}
.background-dark-green{
    background-color: #293306;
}
.background-white{
    background-color: #ffffff;
}
.baackground-blue-diamond{
    background-color: #39DBFF;
}
.background-very-light-pink{
    background-color:#FFF0F0;
}
.background-satin-linen{
    background-color: #E0E5CF;
}
.blue-diamond-color{
    background-color: #00a3ff;
}
.background-white-color{
    background-color: white !important;
}
.background-air-force-color{
background-color:#255AF6!important;
}
.background-orange-color{
    background-color:#E08330;
}
.light-red{
   color: #FF3F3F!important;
}
.light-orange-color{
    color:#E08330!important;
}
.light-grey-color{
    color:#AAA9A9 !important;
}