.edit-viewer-inputs.scrollDiv label {
  font-size: 12px;
  line-height: 1.5;
  margin-top: 6px;
  font-weight: 700;
}
.edit-viewer-inputs.scrollDiv input[type="text"],
.edit-viewer-inputs.scrollDiv input[type="number"] {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 2px;
  padding: 2px 6px;
  width: 180px;
}
.editor-map .leaflet-top.leaflet-right .leaflet-draw.leaflet-control {
  margin-bottom: calc(100vh - 350px);
}
.btn-primary.disabled,
.btn-primary:disabled {
  cursor: not-allowed;
}
.edit-viewer-inputs {
  color:#0D1A40;
  border: 1px solid #dddddd;
  border-radius: 10px;
  width: 53px;
  display: block;
  margin: auto;
  text-align: center;
}
a {
  color: #0D1A40 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.yearDropDown {
  height: 60px;
  background-color: #f9f9f9 !important;
  border-radius: none;
}
.yearButton {
  margin-bottom: 0;
  font-size: 10px;
  color:#0D1A40;
  text-align: left;
  line-height: 8px;
  margin-left: 10px;
  font-weight: bold;
  position: absolute;
  top: 12px;
  left: 16px;
}
.year-dropdown-toggle {
  height: 60px;
  background-color: #f9f9f9 !important;
  border-radius: none;
  white-space: nowrap !important;
}
.editviewer-down-icon {
  width: 100%;
  height: 16px;
  color:#0D1A40
}
.selected-year-dropdown {
  text-align: left;
  line-height: 0.1px;
  margin-left: 10px;
  position: absolute;
  top:30px;
}
.selected-year {
  font-size: 12px;
  color:#0D1A40;
}
.option {
  margin-left: 10px;
  font-size: 12px;
  
}
.autocomplete {
  color:#0D1A40!important;
  border: none;
  box-shadow: none;
  margin-top: 10px;
  font-size: 12px;
  margin-right: 20px;
}

.editor-map {
  z-index: 9;
}
button.supply-plot-details-button {
  color:#0D1A40;
  z-index: 9;
  right: 30px;
  top: 15px;
  font-size: 12px;
  font-weight: 700;
}
.supply-plot-details-lable {
  font-size: 14px;
  font-weight: 700 !important;
  color:#0D1A40;
}
.supply-plot-details-close-button {
  color:#0D1A40;
  border-bottom: 1px solid #0D1A40;
  padding-bottom: 0px !important;
  float: right;
  margin-bottom: 10px;
}
.editor-map.editor-map-to-left .leaflet-top.leaflet-right .leaflet-draw.leaflet-control {
  margin-right: 41%;
}
.editor-map.editor-map-to-left .leaflet-right .leaflet-control {
  margin-right: 41%;
}
.steps-button {
  font-size: 12px;
  font-weight: 600;
  background: #ededed;
  width: 65px;
  height: 18px;
  text-align: center;
  border-radius: 30px;
  min-width: 65px;
  color:#0D1A40;
}
.editor-input-fields-label {
  font-weight: bold;
  font-size: 10px;
  color:#0D1A40;
}
.editor-input-fields-label.disabled {
  color: #dddddd;
}
.editor-buttons {
  border-radius: 20px !important;
  height: 30px !important;
  padding: 0px 14px !important;
  font-size: 12px !important;
  margin-top: 16px;
}
.new-editor-buttons {
  border: none;
  border-radius: 20px !important;
  color: #ffffff !important;
  height: 30px !important;
  padding: 0px 14px !important;
  font-size: 12px !important;
  margin-top: 16px;
}
span.action-label {
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
  line-height: 16px;
  color:#0D1A40;
}
.action-row:after {
  display: none;
}
.download-info {
  font-size: 12px;
  font-style: italic;
  color:#0D1A40;
}
.no-data-label {
  font-size: 12px;
  font-style: italic;
  color: #e74b7a;
}
.attachment-label {
  font-size: 12px;
  font-weight: 700;
}
.uploading-wrappper {
  height: calc(100% - 240px);
  overflow-y: auto;
}
span.uploading-text {
  font-size: 12px;
  font-weight: 700;
}
.spinner-border.text-warning {
  height: 24px;
  width: 24px;
}
.plot-details-row {
  padding: 12px;
  margin-left: 0px !important;
  border-bottom: 1px solid #dddddd;
  font-size: 16px;
  font-weight: 400;
  color:#0D1A40
}
.plot-details-row.plot-details-header {
  border-top: 1px solid #dddddd;
  font-weight: 700;
  color :#0D1A40;
}
.custom-checkbox {
  font-size: 12px;
  padding: 5px 10px 5px 10px;
}
.selectplots-class {
  position: absolute;
  z-index: 14;
  top: 48px;
  right: 180px;
  background-color: white;
  width: 150px;
  max-height: 50%;
  overflow-y: scroll;
}

.select-plot-button {
  right: 180px;
  width: 150px;
  text-align: left;
  z-index: 9;
  top: 15px;
  font-size: 12px;
  font-weight: 700;
  background: white;
  height: 32px;
  border-radius: 5px
}
.plots-distance-button {
  right: 345px;
  width: 274px;
  text-align: left;
  z-index: 9;
  top: 15px;
  font-size: 12px;
  background: white;
  height: 32px;
  border-radius: 5px;
  color:#0D1A40;
}

.plots-rotate-bar {
  right: 59px;
  width: 210px;
  text-align: left;
  z-index: 9;
  top: 195px;
  font-size: 12px;
  background-color: white;
  height: 40px;  
  border-radius: 5px; 
  display: inline-flex;
}


#sliderRange{
  width: 100%;
  margin: auto;
}

.sliderDegree{
  position: absolute;
  left: 193px;
  top: 12px
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  background-color: #df8230;
  border: 1px solid #df8230;
  height: 15px;
  width: 15px;
  border-radius: 8px 
}

input[type="range"]{
  -webkit-appearance: none !important;
  -moz-apperance: none;
  border-radius: 6px;
  height: 6px;
  background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0.15, #ffe8bc),
      color-stop(0.15, #ffe8bc)
  );
}

input[type="range"]::-moz-range-thumb {

  background-color: #df8230;
  border: 1px solid #df8230;
  height: 15px;
  width: 15px;
  border-radius: 8px;
  cursor: pointer;
}

.plots-dimension-button {
  right: 635px;
  width: 274px;
  text-align: left;
  z-index: 9;
  top: 15px;
  font-size: 12px;
  background: white;
  height: 32px;
  border-radius: 5px;
  color:#0D1A40
}

.undo-redo-buttons {
  width: 32px;
  height: 32px;
  right: 59px;
  z-index: 9;
  position: absolute;
  border: none;
  bottom: calc(100vh - 180px);
  background: white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  text-align: justify;
  font-size: 10px;
  border-radius: 4px;
  color: #0D1A40;
  align-items: center;
  padding-left: 1px;
}

.undo-button-locate {
  top: 240px;
}

.redo-button-locate {
  top: 275px;
}
.apply-button {
  color:#0D1A40;
  width: 57px;
  height: 25px;
  border-radius: 20px;
  border: 1px solid #0D1A40;
  background-color: transparent;
  font-size: 12px;
  font-weight: 700;
}
.distance-label {
  font-size: 10px;
  font-weight: 400;
}
input.edit-viewer-inputs.distance {
  width: 39px;
  height: 25px;
}
input.edit-viewer-inputs.angle {
  width: 39px;
  height: 25px;
}
.plots-distance-button-left {
  top: 60px;
  left: 3%;
}
.plots-dimension-button-left {
  top: 60px;
  left: calc(3% + 285px);
}
.selecet-plot-inside-button {
  height: 32px;
  font-size: 12px;
  font-weight: 700;
  color:#0D1A40;
}
.save-label {
  font-size: 24px;
  font-weight: 700;
  color:#0D1A40;
}
.error-message-label {
  font-size: 12px;
  font-weight: 400;
  color: #fb6107;
}
.font-24 {
  font-size: 24px;
}
.success-modal.modal.show .modal-content {
  width: 550px;
}
.edit-dropdown-toggle {
  border-color: #dddddd !important;
  border: 1px solid #dddddd;
  border-radius: 10px;
  width: 135px !important;
  height: 30px;
  font-size: 12px;
  color:#0D1A40!important;
}
.edit-chevrondown-icon {
  margin-left: 6px;
}
.edit-dropdodwn-menu {
  width: 30%;
  top: 10% !important;
  transform: translate3d(315.667px, 55.3333px, 0px) !important;
}
.dropdownitem-heading {
  font-weight: 700;
  font-size: 10px;
  font-family: Helvetica;
  color:#0D1A40;
  margin-bottom: 8px;
}
.dropdownitem-data {
  font-weight: 700;
  font-size: 12px;
  font-family: Helvetica;
  color:#0D1A40;
}
.edit-data {
  color: #979797 !important;
  margin: 0px 0px 0px -3px;;
}
.edit-menu {
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
  color: #3d4c0a;
  align-items: center;
  padding: 4px 4px 4px 0px;
  align-items: center;
}
.edit-newlayouts {
  font-size: 12px;
  font-family: Helvetica;
  color:#0D1A40;
  margin-bottom: 18px;
  margin-left: 8px;
}
.edit-bottom-layout {
  margin-bottom: 15px;
}
.edit-menu-name {
  margin: 0px 4px 0px 8px;
}
.edit-dropdown-item {
  overflow-x: hidden;
  padding: 12px 0px;
}
.plot-checkbox {
  color: #0D1A40;
  font-size: 12px;
  font-family: Helvetica;
  padding: 0 10px;
}

.padding-notes-container {
  padding-left: 40px;
}

.notes-title {
  font-size: 12px;
  font-family: Helvetica;
  color: #979797;
}

.notes-text {
  max-height: 100px;
  overflow-y: auto;
  font-size: 12px;
  font-family: Helvetica;
  padding-right: 5px;
  line-height: 13.8px;
  letter-spacing: 0.2px;
  color: #0D1A40;
}
.edit-measure-icon {
  z-index: 9;
  position: absolute;
  width: 32px;
  height: 32px;
  border: none;
  right: 59px;
  top: 90px;
  bottom: calc(100vh - 180px);
  background: white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
}

.edit-measure-icon.isWhite:hover,
.edit-measure-icon.isWhite:active,
.edit-measure-icon.isWhite:focus,
.edit-measure-icon.isWhite:active:focus {
  background-color: white !important;
  border: none !important;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65) !important;
}
.edit-measure-icon.isGrey:hover,
.edit-measure-icon.isGrey:active,
.edit-measure-icon.isGrey:focus,
.edit-measure-icon.isGrey:active:focus {
  background-color: #e0e5cf !important;
  border: none !important;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65) !important;
}
.edit-viewer .leaflet-control-measure-toggle.js-toggle {
  display: none !important;
}
.edit-viewer a.js-start-area.start,
.edit-viewer a.js-start-linear.start {
  color: #6269cd !important;
}
.edit-measure-icon-left {
  right: 26px;
}

.custom-tooltip {
  margin-top: 5px;
  color: white;
}

.custom-tooltip .tooltip-inner {
  background-color:#255AF6!important;
  font-size: 12px;
  font-family: Helvetica;
}
.save-tooltip .tooltip-inner {
  width: 375px !important;
  height: 52px !important;
  max-width: 375px !important;
  position: absolute;
  right: 0px;
  bottom: 21px;
  background-color: #255AF6;
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: Helvetica;
}
.tooltip-info-circle {
  position: absolute;
  left: 15px;
  top: 7px;
}
.tooltip-save-text {
  color:white;
  font-size: 12px;
  padding-right: 56px;
}
.tooltip-submit-text {
  color:white;
  font-size: 12px;
  padding-right: 8px;
}

.checkbox-popup .leaflet-popup-content-wrapper{
  background: transparent;
}

.checkbox-popup .leaflet-popup-tip{
  background: transparent;
}

.checkbox-popup 
input[type="checkbox"] {
  margin: -2px;
}


.toggleSwitch{
  width: 20px;
  height: 20px;
}
.flight-date-wrapper.edit-viewer-flight-date-wrapper {
  top: 120px;
  z-index: 9999;
}

.autocomplete::placeholder{
  color:#0D1A40;
}

.form-control:focus {
  background-color: #fff;
  border-color: #0D1A40;
  outline: 0;
  box-shadow: none!important;
  
}
.edit-viewer-search-input::placeholder{
  padding-left: 0px;
  color: #0D1A40 !important;
 
}
.edit-viewer-search-input{
  padding-left: 15px;
  border:none;
  height: 40px;
  background-color: #E0E5CF52;
  margin-top: 6px;
  border-radius: 20px;
  font-size: 12px;
  color: #0D1A40 !important;
  font-weight: 400;
  width: 100%;
}
.edit-viewer-dropdown-toggle {  
  border:none;
  border-radius: 10px;
  width: 324px !important;
  height: 30px;
  font-size: 12px;
  color:#0D1A40!important;
}
.edit-viewer-dropdodwn-menu {
  width: 440px;
  left:109px !important;
   transform:translate(0px, 61px) !important;
}
.edit-viewer-dropdown-item {
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 12px;
  max-height: 375px;
}
.edit-viewer-newlayouts {
  font-size: 12px;
  font-family: Helvetica;
  color:#0D1A40;
  margin-bottom: 18px;
  margin-left: 8px;
  font-weight:400;
}
.edit-viewer-draft-dropdodwn-menu {
  width: 350px;
  left:109px !important;
  transform:translate(0px, 62px) !important
 
}
.edit-viewer-dropdown-item::-webkit-scrollbar-thumb{
  background-color: #aaa9a9;
}
.delete-modal .modal-dialog {
  max-width: 514px;
}
path.leaflet-interactive:not(.leaflet-path-draggable):not(.layer-measure-resultline):not(.layer-measureboundary)[fill="none"] {
  stroke: none !important;
}
.selection-row {
  padding: 5px 10px;
}
.selection-label {
  font-size: 12px;
  width: 80px;
}
.selection-action-button {
  cursor: pointer;
}