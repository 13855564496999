.mobile .modal-content {
  background-color: #0d1a40;
  top: 61px;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.tab .modal-content {
  background-color: #0d1a40;
  top: 40px;
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: scroll;
}
.mobile .modal-dialog {
  margin: 0 !important;
}
.tab .close-button {
    position: absolute !important;
    cursor: pointer  !important;
    font-size: 20px;
    right: 10px !important;
    color: #fff !important;
    top: 0px !important;
  }

.mobile .close-button {
  position: absolute !important;
  cursor: pointer  !important;
  font-size: 20px;
  right: 10px !important;
  color: #fff !important;
  top: 0px !important;
}

.mobileClearAll {
  font-size: 16px;
  font-weight: 700;
}
.doneOrManualImg {  
  font-weight: 700;
  font-size: 12px;
  color: #0D1A40;
}

.assessment-input {
  width: 16px;
  height: 16px;
}
.assessmentNames {
  padding-left: 8px;
  font-size: 12px;
  font-weight: 400;
  color: #0D1A40;
}
.foundIndexCss {
  font-size: 12px;
  font-weight: 600;
}
.typeToDate {
  height: 48px;
  border-bottom: 1px solid rgb(237, 237, 237);
}
.trialButtons{
  width: 150px;
  overflow-wrap: break-word;
  font-size: 12px;
  border-radius: 20px;
  color: #FFFFFF;
  margin: 5px;
  border: none;
  font-weight: 700;
}

.protocolTrial-dropdown{
  background-color: #FFFFFF;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  text-align: center;
  width: 180px;
  top: 60px;
}
.selectText{
  font-size: 16px;
  font-family: Helvetica;
  font-weight: 400;
  margin-top: 6%;
  color: #0D1A40;
}


.mobile-topbar-tooltip {
  margin-top: -11px;
  white-space: nowrap;
}

.mobile-topbar-tooltip .tooltip-inner {
  background-color:#255AF6!important;
  font-size: 12px;
  min-width: 250px;
  font-family: Helvetica;
  max-width: 800px !important;
  margin-top:5px;
  height: 69px;
  position:fixed;
  text-align: left;
 
}
.save-tooltip .tooltip-inner {
  width: 1005px !important;
  height: 52px !important;
  max-width: 700px !important;
  right: 0px;
  bottom: 21px;
  background-color: #255AF6;
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: Helvetica;
}
.toogle-desktop {
margin-left: "-20px";
}

.mobileTop-feedback{
  font-size: 0.875rem;
  text-transform: capitalize;
  text-decoration: none;
  background: none;
  border: none;
  color: #ffffff !important;
  font-weight: 700;
}

.mobileTab-vector{
  display: flex;
  gap: 9px;
  margin-Left:-6px;
}