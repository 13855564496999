.custom-weather-dropdown-item:hover {
  background-color: transparent !important;
}

.weather-chevron-down {
  position: absolute;
  left: 180px;
  top: 10px;
}
.weather-data-name {
  font-size: 12px;
  font-weight: 400;
  color: #0d1a40;
  font-family: Helvetica;
}
.weather-dropdown-data-div {
  text-align: left;
  position: relative;
}
.weather-option {
  padding-top: 4px;
  text-align: left;
  line-height: 0.1px;
}
.weather-selected-text {
  font-size: 12px;
  color: #0d1a40;
  font-family: Helvetica;
}
.weather-dropdown-menu {
  width: 220px;
  height: 351px;
  max-height: 325px;
  overflow-y: auto;
  transform: translate3d(47px, 42.6667px, 0px) !important;
}

/*........Calendar css..............*/

.weather-calendar-heading {
  background-color: #ffffff;
  width: 135px;
  height: 40px;
  border: 0.5px solid #e8e8e8;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.weather-calendar-icon {
  margin-left: 15px;
  margin-right: 17px;
}
.weather-calendar-selected-option {
  color: #0d1a40;
  font-size: 12px;
  font-family: Helvetica;
  margin-left: -11px;
}
.weather-calendar-body {
  position: absolute;
  left: 351px;
  z-index: 99;
}
.weekends {
  color: #aaa9a9 !important;
}
.weekday {
  color: #0d1a40 !important;
}
.react-calendar__tile {
  padding: 2px 3px !important;
}
.react-calendar__tile--active {
  background-color: #ffe8bc !important;
  border-radius: 20px;
  padding: 0px !important;
}
.react-calendar__month-view__weekdays {
  color: #0d1a40 !important;
  border-bottom: 0.5px solid #e8e8e8 !important ;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 1px !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}
.react-calendar__navigation {
  height: 37px !important;
  margin-bottom: 0px !important;
}
.calendar {
  height: 188px !important;
  width: 189px !important ;
}
.react-calendar__navigation button {
  text-transform: uppercase !important;
  font-family: Helvetica !important;
  font-size: 12px !important;
  color: #0d1a40 !important;
  font-weight: 700;
  white-space: nowrap;
}
.react-calendar__month-view__weekdays__weekday--weekend {
  color: #aaa9a9 !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
  visibility: hidden !important;
}

.react-calendar__tile--now {
  background-color: #ffe8bc !important;
}

/*........device dropdown css..............*/

.weather-dropdown-toggle {
  border: none !important;
  width: 210px !important;
  height: 40px !important;
  margin: 0px 0px 0px 10px;
  padding: 0px 0px 0px 8px !important;
  border-radius: 3px 0px 0px 3px;
}

.whether-dropdown.dropend button {
  width: 100%;
  align-self: flex-start;
  text-align: left;
  height: 49px;
  padding: 0 12px;
  border-bottom: 1px solid rgb(237, 237, 237);
  border-radius: 0;
}

.whether-dropdown.whether-dropdown-type.dropend button {
  height: 32px;
  padding: 0 16px;
  border-bottom: 1px solid rgb(237, 237, 237);
}

.whether-dropdown.dropend .dropdown-menu {
  width: 280px;
  overflow-y: auto;
  transform: 3d (361px, 0px, 0px) !important;
  height: 350px;
  max-height: 350px;
}
.env-clear-all-button {
  float: right;
  margin-right: 12px;
  border-bottom: 1px solid;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  color: #0d1a40;
  font-weight: 700;
}

.des-env-all-button {
  color: #0d1a40;
  font-weight: 700;
  font-size: 16px;
  border-bottom: 1px solid #0d1a40;
  border-bottom: none !important;
  width: 100%;
  height: 48px !important;
  text-align: right !important;
  display: flex;
  justify-content: flex-end;
}

.env-dropdown.dropend .dropdown-menu {
  width: fit-content;
  overflow-y: auto;
  transform: translate3d(221px, 0px, 0px) !important;
  height: 300px !important;
}

.env-dropdown-menu-show {
  width: 210px !important;
  height: 321px !important;
}
.env-dropdown.dropend button {
  width: 100%;
  align-self: flex-start;
  text-align: left;
  height: 49px;
  padding: 0 12px;
  border-bottom: 1px solid rgb(237, 237, 237);
  border-radius: 0;
}

.env-dropdown.env-dropdown-type.dropend button {
  height: 32px;
  padding: 0 16px;
  border-bottom: 1px solid rgb(237, 237, 237);
}

.env-main-dropdown .env-dropdown-menu {
  transform: translate3d(0px, 60px, 0px) !important;
}

.env.dropend .dropdown-menu {
  width: 280px;
  overflow-y: auto;
  transform: translate3d(211px, 0px, 0px) !important;
  height: 350px;
  max-height: 350px;
}

span.env-option {
  color: #0d1a40;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}
.env-row-hover:hover,
.env-row-hover:focus {
  background-color: #ffe8bc;
}
.env-dropdown.show.dropend .dropdown-toggle {
  background-color: #ffe8bc !important;
}

.dropdown-badge-container {
  display: flex;
  align-items: flex-start;
}
.dot-indicator {
  width: 4px;
  height: 4px;
  background-color: #e08330;
  border-radius: 50%;
  display: inline-block;
  position: fixed;
  margin-left: -8px;
  padding: 0px 0px;
}
.selected-count {
  width: 18px;
  color: #0d1a40;
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  left: 155px;
}

.badge {
  width: 148px;
  height: 22px;
  top: 192px !important;
  left: 232px !important;
  gap: 0px;
  border-radius: 10px 0px 0px 0px;
  opacity: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.badge-text1 {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 13.8px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #255af6;
  padding-right: 10px;
  margin-top: 2px;
}

.badge-text2 {
  font-family: Helvetica;
  font-size: 12px;
  top: 196px;
  left: 242px;
  gap: 0px;
  color: #0d1a40;
  padding-right: 12px;
}
.badge-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 0.5px solid #e8e8e8 !important;
  margin-top: 5px;
  margin-left: 32px;
  border-radius: 0px 3px 3px 0px;
  background: white;

}
.badge-container-two {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 0.5px solid #e8e8e8 !important;
  margin-left: 32px;
  border-radius: 0px 3px 3px 0px;
  background: white;
}

.badge-item {
  display: flex;
  align-items: center;
  margin-right: 16px;
  margin-bottom: 3px;
  border: none;
  background-color: #f1f2f3;
  border-radius: 10px;
}
.newcrossIcon {
  width: 12px;
  height: 12px;
}

.apply-container {
  padding: 10px;
  width: 80px;
  margin-top: 5px;
  margin-left: 2px;
}
.env-apply-button {
  color: #0d1a40;
  width: 57px;
  border-radius: 20px;
  border: 1px solid #0d1a40;
  height: 25px;
  background-color: #0d1a40;
  font-size: 12px;
  font-weight: 700;
  margin-top: -4px;
  /* margin-right: 24px; */
  float: inline-end;
}
.env-text {
  margin: 30px 0px 30px 15px;
  font-family: Helvetica;
  font-weight: 700;
  color: #0d1a40;
  font-size: 14px;
}
.env-expand-btn {
  margin: 30px 0px 10px 0px;
  border: none !important;
  height: 40px !important;
  background-color: white !important;
  box-shadow: none !important;
}

.env-cross-btn {
  margin: 30px 0px 10px 0px;
  border: none !important;
  height: 40px !important;
  background-color: white !important;
  box-shadow: none !important;
}

.select-text-toggle-div {
  text-align: left;
  position: relative;
  margin-top: 7px;
}
.select-device-text {
  font-size: 12px;
  font-weight: 400;
  color: #0d1a40;
  font-family: Helvetica;
}
.selected-count-text {
  font-size: 14px;
  font-weight: 700;
  color: #0d1a40;
  margin-left: 3px;
}

.chevron-down-icon {
  position: absolute;
  left: 180px;
  margin-top: 5px;
}

.dropdown-menu-env {
  width: 220px;
  height: 300px;
  transform: translate(47px, 48px) !important;
}

.chip-span {
  margin: 6px 0px 0px 20px;
  display: flex;
  flex-wrap: wrap;
}

.custom-days-dropdown .dropdown-item {
  background-color: transparent !important;
  color: #0d1a40 !important;
}

.custom-days-dropdown .dropdown-item:active {
  background-color: transparent !important;
  color: #e08330 !important;
}

.weatherChart-backward .dropdown-menu-show {
  min-width: 80px !important;
}

.weatherChart-backwardDays {
  width: 108px !important;
  background-color: #ffffff !important;
  border: 0.5px solid #e8e8e8;
  border: none !important;
  height: 40px !important;
}

.weatherChart-backward .dropdown-menu {
  position: absolute !important;
  font-size: 1rem;
  color: #0d1a40;
  list-style: none;
  background-color: #fff;
  max-width: 106px;
  margin: 0 !important;
  border: none !important;
  border-radius: 0 !important;
  z-index: 9;
}

.env-loader-container {
  display: flex;
  justify-content: center;
  position: relative;
  top: 35%;
}

.rangeslider-container .rangeslider-mask-min{
  fill:white !important;
  opacity: 1;
}
.rangeslider-container .rangeslider-mask-max{
  fill:white !important;
  opacity: 1;
}

.js-plotly-plot .plotly .cursor-ew-resize {
  cursor: pointer;
}
.env-close-button {
  position: absolute;
  right: 2%;
  top: 5%;
  cursor: pointer;
  color: #0D1A40;
  font-size: 20px;
}
