.font-size-double-extra-small {
  font-size: 8px;
}
.font-size-extra-small {
  font-size: 10px;
}
.font-size-small {
  font-size: 12px;
}
.font-size-medium {
  font-size: 14px;
}
.font-size-large {
  font-size: 16px;
}
.font-size-extra-large {
  font-size: 18px;
}
.font-size-double-extra-large {
  font-size: 20px;
}
.font-size-big {
  font-size: 22px;
}
.font-size-extra-big {
  font-size: 24px;
}
.font-size-double-extra-big {
  font-size: 25px;
}
.font-size-extra-small-rem {
  font-size: 0.75rem;
}
.font-size-small-rem {
  font-size: 0.76563rem;
}
.font-size-medium-rem {
  font-size: 0.875rem;
}
.font-size-large-rem {
  font-size: 1.3rem;
}
.font-family-Helvetica {
  font-family: Helvetica;
}
.font-family-HelveticaNeue {
  font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.font-family-monospace {
  font-family: "Roboto Mono", monospace;
}
.font-family-noto-sans {
  font-family: "Noto Sans";
}
.font-family-sans-serif {
  font-family: "sans-serif";
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}
.font-style {
  font-style: normal;
}
.cursor-pointer {
  cursor: pointer;
}
.data-button {
  background-color: #FFE8BC;
  color: black;
  border: 1px solid #E08330;
}
.bookmark-icon-color {
  color:#E08330!important;
}
.leaf-text{
  color: #255AF6;
}
.bookmark-style-empty {
  color: #E08330!important
}
.button-back-border {
  background-color: transparent;
  border: none !important;
}
.exclamation-triangle-fill{
  color:#FF3F3F!important;
}
.accent-red{
  accent-color:red!important
}
input[type='radio']:before {
  content: '';
  display: block;
  width: 70%;
  height: 70%;
  margin: 2px auto;
  border-radius: 50%;
} 
input[type='radio'] {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  outline: none;
  vertical-align: middle;
  border: 1px solid #0D1A40;
  accent-color: #0D1A40;
  margin: 3px 4px 3px 0px;
}
 
input[type="radio"]:checked:before {
  background: #E08330 !important;  
}
input[type="radio"]:checked {
border-color: #E08330 !important;
}

input[type='checkbox']:checked {
  background-color: #E08330 !important;
  border: 1px solid #E08330;
}
input[type='checkbox']:checked:after {
  content: '\2713';
  color:white;
  border: 1px solid #E08330;
}
input[type='checkbox']{
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  width: 16px !important;
  height: 16px !important;
  appearance:none;
  border-radius:10%;
  border: 1px solid #0D1A40;
  box-shadow: none;
  font-size: 10px;
  margin: 3px 3px 3px 4px;
}
.btn-primary {
  border-color: #0D1A40 !important;
}

::-webkit-scrollbar{
  width: 6px;
}
::-webkit-scrollbar-track{
  background: none;
  width: 6px;
}
::-webkit-scrollbar-thumb{
  background: #AAA9A9;
  border-radius: 3px;
}

