:root {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100%;
  padding-right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: ".875rem";
  line-height: 1.5;
  font-weight: 400;
  font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #FAFBFE;
  color: #555555;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

code {
  font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@media (-webkit-device-pixel-ration: 1.5){
  body {
    zoom: 0.8;
  }
}
