.newBarChart .js-plotly-plot .plotly .modebar {
  left: 30% !important;
}
.iphoneSECompare .js-plotly-plot .plotly .modebar {
  left: 35% !important;
  top: 20px;
}

.newCompareChart .js-plotly-plot .plotly .modebar {
  left: 36% !important;
  top: 30px !important;

}
.desktopCompare .js-plotly-plot .plotly .modebar {
 left: 70% !important;
  top: 0px !important;
}
.extraSmallCompare .js-plotly-plot .plotly .modebar {
  left: 32% !important;
  top: 20px !important;

}
.iphoneSE .js-plotly-plot .plotly .modebar {
  left: 25% !important;
}

.iphoneSELandScape .js-plotly-plot .plotly .modebar {
  left: 70% !important;
  top: -14px;
}
.landscapeChart .js-plotly-plot .plotly .modebar {
  left: 75% !important;
}
.landscapeCompareChart .js-plotly-plot .plotly .modebar {
  left: 75% !important;
  top: 0px !important;

}

.iphoneSELandScapeCompare .js-plotly-plot .plotly .modebar {
  left: 65% !important;
  top: 0px !important;

}

.landscapeCorrelationeChart .js-plotly-plot .plotly .modebar {
  left: 65% !important;
  top: -8px;
}

.iphoneSELandScapeCorrelation .js-plotly-plot .plotly .modebar {
  left: 47% !important;
  top: -8px;
}

.landscapeFlightChart .js-plotly-plot .plotly .modebar {
  left: 70% !important;
  top: -13px;
}

.correlation-coeefficient {
  color: #0D1A40;
  font-size: 12px;
  font-weight: 400;
  font-family: Helvetica, sans-serif;
  margin-left: 33px;
  position: relative;
}

.chart-title {
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-family: Helvetica, sans-serif;
  margin-top: 5%;
}

.flight-one-date-error {
  display: block;
  text-align: center;
  margin-top: 20px;
  font-size: 12px;
  font-family: Helvetica, sans-serif;
}
.error-message {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 35px;
}

.chart-container {
  overflow-x: hidden;
}

.model-height {
  height: 100vh;
}

.mobile-landscape-height {
  height: 115vh;
}
.mobile-iphoneSE-height {
  height: 115vh;
}

.default-height {
  height: 100vh;
}

.mobile-height {
  height: 80vh !important;
}

.model-width {
  width: 100%;
}

.not-model-but-mobile-width {
  width: 100vw;
}
.not-model-not-mobile-width {
  width: 47vw;
}
.defualt-width {
  width: 57vw;
}

.different-charts-treatment {
  width: 100%;
  margin: auto;
  height: 90%;
  overflow: hidden;
}

.different-chart-compare {
  width: 100%;
  margin: auto;
  overflow: hidden;
}

.iphoneSE-different-chart-compare {
  height: 160vh;
}

.normal-different-chart-compare {
  height: 120vh;
}

.different-charts-treatment-child {
  display: flex;
  flex-direction: row;
}

.flightChart .js-plotly-plot .plotly .modebar {
  left: 35% !important;
}

.iphoneSEFlight .js-plotly-plot .plotly .modebar {
  left: 30% !important;
}

.compare-chart .legend {
  display: contents !important;
}

.correlation-chart .legend {
  touch-action: pan-y;
}

.chart-loader-container {
  display: flex;
  justify-content: center;
  position: relative;
  top: 10%;
}

.chart-spinner {
  width: 64px;
  height: 64px;
  color: green;
}

.custom-legend {
  font-family: "Open Sans", verdana, arial, sans-serif;
  font-size: 12px;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px 20px 0px 20px;
  overflow: auto;
  position: relative;
}
.custom-legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  cursor: pointer;
}
.custom-legend-item:last-child {
  margin-bottom: 0;
}
.custom-legend-symbol {
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  margin-right: 10px;
  background-color: #444;
}
.custom-legend-text {
  flex-grow: 1;
}
.chart-dropdown .dropdown-toggle {
  background-color: white;
  font-size: 12px;
  font-family: Helvetica, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
  border-radius: 4px;
  width: 151px;
  border: 1px solid rgb(237, 237, 237);
}


.dropdown-wrapper .custom-dropdown-menu {
  position: absolute !important;
  font-size: 1rem;
  color: #0D1A40;
  list-style: none;
  background-color: #fff;
  max-width: 151px;
  margin: 0 !important;
  border: none !important;
  border-radius: 0 !important;
  top:20px !important;
}

.count-badge {
  color: white;
  font-size: 12px;
  font-family: Helvetica, sans-serif;
  color: #0D1A40;
  
}

.dropdown-wrapper .dropdown-menu-show{
  min-width: 100px !important;
  left: 30px !important;
}
