.mobile-overlay-toggle{
    margin-left:0px!important;
    top: 0px !important;
    box-shadow: none!important;
}
  
.option-cross{
    height: 65px !important;
    padding-left: 20px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.baseOF-landscapedropdown.dropend .dropdown-menu {
    width:45vw !important;
    overflow-y: auto;
    transform: translate3d(45vw, 65.5px, 0px) !important;
    min-height: calc(100vh - 126px) !important;
    max-height: calc(100vh - 126px) !important;
    max-width: 45vw !important;
    min-width: 45vw !important;
    box-shadow: none!important;
    position: absolute;
    z-index: 1;
}

.baseOF-landscapedropdown-menu-show{
    width:210px!important;
    height:351px!important
}
.baseOF-landscapedropdown.dropend button {
    width: 100%;
    align-self: flex-start;
    text-align: left;
    height: 65px;
    padding: 0 12px;
    border-bottom: 1px solid rgb(237, 237, 237);
    border-radius: 0;  
}

.baseOF-mobiledropdown.dropend .dropdown-menu {
    width: 100vw;
    overflow-y: auto;
    transform: translate3d(0px, 0px, 0px) !important;
    min-height: 360px !important;
    max-height: 394px !important;
    box-shadow: none !important;
}
 
.baseOF-mobiledropdown.baseOF-dropdown-type.dropend button {
    height: 32px;
    padding: 0 16px;
    border-bottom: 1px solid rgb(237, 237, 237);
}

.baseOF-mobiledropdown-menu-show{
    width:210px!important;
    height:351px!important
}
.baseOF-mobiledropdown.dropend button {
    width: 100%;
    align-self: flex-start;
    text-align: left;
    height: 65px;
    padding: 0 12px;
    border-bottom: 1px solid rgb(237, 237, 237);
    border-radius: 0;  
}
.hide-flight.dropend .dropdown-menu{
    display:none;
}
.baseOF-mobiledropdown.baseOF-dropdown-type.dropend button {
    height: 32px;
    padding: 0 16px;
    border-bottom: 1px solid rgb(237, 237, 237);
}
  
.baseOF-main-dropdown .baseOF-dropdown-menu {
    transform: translate3d(0px, 60px, 0px) !important;
}
  
.baseOF.dropend .dropdown-menu {
    width: 280px;
    overflow-y: auto;
    transform: translate3d(211px, 0px, 0px) !important;
    min-height:300px ;
    max-height: 350px;
}

.dd-mobile-flight-title {
    color: #0D1A40;
    font-size: 12px;
    font-style: normal;
    margin-top: -39px;
    padding-left: 41px;
    margin-bottom: 24px;
}
.dd-mobilelandscape-flight-title{
    color: #0D1A40;
    font-size: 12px;
    font-style: normal;
    padding-bottom:15px;
    margin-top:8px;
    margin-left: 22px
}

.mobile-landscape-overlay-menu{
    border-bottom: 0.5px solid #E8E8E8 !important;
    height:72px !important;
    padding-top:15px;
    padding-left:20px;
    width:200px
}

.mobileL-Flight{
    border-bottom: 0.5px solid #E8E8E8 !important;
    margin-top:72px;
}
.flight-dropdown .dropdown-toggle {
    padding: 0px 12px;
    height: 65px;
}